import React from 'react'

import Layout from '../../layout/layout'

import 'animate.css/animate.min.css'

type pageProps = {
  pageContext: {
    title: string,
    description: string,
    trees?: object,
    blocks?: [any],
    articles?: [any],
    stories?: [any],
    searchIndexes?: [any]
  },
}

export default ({ pageContext: { title, description, blocks, trees, articles, stories, searchIndexes } }: pageProps) => {
  
  return (
    <Layout 
      title={title} 
      pageDescription={description} 
      blocks={blocks}
      trees={trees}
      articles={articles}
      stories={stories}
      searchIndexes={searchIndexes}
    />
  )
}